import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { navigate } from 'gatsby'
import { makeRequest } from '../../helpers'
import ClaimView from './claim-view'

const ClaimController = (props) => {
  const { slug } = props
  const [loading, setLoading] = useState(true)
  const [claimInsurance, setClaimInsurance] = useState({})
  const { i18n } = useTranslation()
  const getHowToClaim = async () => {
    setLoading(true)

    const headers = {
      'Content-Type': 'application/json',
    }
    makeRequest({
      headers,
      endPoint: 'claims-support/procedures',
      params: {
        plan: slug,
        lang: i18n.language,
      },
    })
      .then(({ data }) => {
        setClaimInsurance(data)
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (process.env.GATSBY_HAS_CLAIM) {
      getHowToClaim()
    } else {
      navigate('/404/')
    }
  }, [slug])

  const viewProps = {
    loading,
    data: claimInsurance,
  }
  return <ClaimView {...viewProps} />
}

export default ClaimController
