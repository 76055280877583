import _ from 'lodash'
import { Link } from 'gatsby'
import React from 'react'
import HTMLRenderer from 'react-html-renderer'
import { useTranslation } from 'react-i18next'
import { Spinner, Seo } from '../../components'
import { getUrlMedia } from '../../helpers'
import IconArrowRight from '../../assets/icons/pacificcross_icons_arrow-right-02.svg'
import IconDownload from '../../assets/icons/pacificcross_icons_download.svg'
import IconArrow from '../../assets/icons/dropdwon-arrow.svg'
import './claims-styles.scss'

const ClaimsView = ({ loading, data }) => {
  const { t } = useTranslation()
  const items = _.get(data, 'steps', 1)
  if (loading) {
    return <Spinner loading={loading} />
  }
  return (
    <div className="container-fluid">
      <Seo
        title={`${t('seo.title')}: How to Claim - ${_.get(data, 'title')}`}
      />
      <div className="container-xxl">
        <div className="claim__head">
          <div className="claim__breadscrumb">
            <Link to="?">
              <span>Filing claims - {_.get(data, 'category')}</span>{' '}
            </Link>
            <IconArrow width="22" fill="#1C75BC" />
            <span>{_.get(data, 'title')}</span>
          </div>
          <h1 className="page-title">{_.get(data, 'title')}</h1>
        </div>
        <div className="claim__body">
          <div className="claim__procedures">
            <h2>{t('claims.procedures')}</h2>
            <div className="row">
              {_.map(_.get(data, 'steps'), (item, idx) => (
                <div key={idx} className="claim__card">
                  <div className="row">
                    <div className="claim__card__content col-10">
                      <p className="claim__card__title">
                        {t('claims.step', { number: idx + 1 })}
                      </p>
                      <div
                        className="rich-text"
                        dangerouslySetInnerHTML={{
                          __html: item.text,
                        }}
                      />
                    </div>
                    {(items.length > idx + 1) && (
                      <div className="claim__card__arrow col-2">
                        <IconArrowRight width="20" />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="claim__forms">
            <h3>{t('claims.forms')}</h3>
            <div className="claim__forms__list">
              {_.map(_.get(data, 'forms'), (item, idx) => (
                <div key={idx} className="claim__downloads">
                  <a
                    href={getUrlMedia(item.link)}
                    download
                    target="_blank"
                    rel="noreferrer"
                  >
                    <IconDownload width="15" />
                    <span>{item.title}</span>
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="claim__richText">
            <h3>{t('claims.support')}</h3>
            <div className="rich-text">
              <HTMLRenderer html={_.get(data, 'supporting_docs')} />
            </div>
          </div>
          <div className="claim__richText">
            <h3>{t('claims.submission')}</h3>
            <ul>
              {_.map(_.get(data, 'submission'), (sub, i) => (
                <li key={i}>
                  <p>{sub.text}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClaimsView
